"use es6";

/* *
 *
 *  Sankey diagram module
 *
 *  (c) 2010-2021 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Nodes = _interopRequireDefault(require("../../Mixins/Nodes"));
var _Point = _interopRequireDefault(require("../../Core/Series/Point"));
var _SeriesRegistry = _interopRequireDefault(require("../../Core/Series/SeriesRegistry"));
var _Utilities = _interopRequireDefault(require("../../Core/Utilities"));
;
var __extends = void 0 && (void 0).__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var ColumnSeries = _SeriesRegistry.default.seriesTypes.column;
var defined = _Utilities.default.defined,
  extend = _Utilities.default.extend;
/* *
 *
 *  Class
 *
 * */
var SankeyPoint = /** @class */function (_super) {
  __extends(SankeyPoint, _super);
  function SankeyPoint() {
    /* *
     *
     *  Properties
     *
     * */
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.className = void 0;
    _this.fromNode = void 0;
    _this.level = void 0;
    _this.linkBase = void 0;
    _this.linksFrom = void 0;
    _this.linksTo = void 0;
    _this.mass = void 0;
    _this.nodeX = void 0;
    _this.nodeY = void 0;
    _this.options = void 0;
    _this.series = void 0;
    _this.toNode = void 0;
    return _this;
    /* eslint-enable valid-jsdoc */
  }
  /* *
   *
   *  Functions
   *
   * */
  /* eslint-disable valid-jsdoc */
  /**
   * @private
   */
  SankeyPoint.prototype.applyOptions = function (options, x) {
    _Point.default.prototype.applyOptions.call(this, options, x);
    // Treat point.level as a synonym of point.column
    if (defined(this.options.level)) {
      this.options.column = this.column = this.options.level;
    }
    return this;
  };
  /**
   * @private
   */
  SankeyPoint.prototype.getClassName = function () {
    return (this.isNode ? 'highcharts-node ' : 'highcharts-link ') + _Point.default.prototype.getClassName.call(this);
  };
  /**
   * @private
   */
  SankeyPoint.prototype.isValid = function () {
    return this.isNode || typeof this.weight === 'number';
  };
  return SankeyPoint;
}(ColumnSeries.prototype.pointClass);
extend(SankeyPoint.prototype, {
  setState: _Nodes.default.setNodeState
});
/* *
 *
 *  Default Export
 *
 * */
var _default = exports.default = SankeyPoint;
module.exports = exports.default;