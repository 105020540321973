"use es6";

/* *
 *
 *  (c) 2010-2021 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Globals = _interopRequireDefault(require("../Core/Globals"));
var _Point = _interopRequireDefault(require("../Core/Series/Point"));
var _Utilities = _interopRequireDefault(require("../Core/Utilities"));
;
var defined = _Utilities.default.defined;
var noop = _Globals.default.noop,
  seriesTypes = _Globals.default.seriesTypes;
/**
 * Mixin for maps and heatmaps
 *
 * @private
 * @mixin Highcharts.colorMapPointMixin
 */
var colorMapPointMixin = {
  dataLabelOnNull: true,
  /* eslint-disable valid-jsdoc */
  /**
   * Color points have a value option that determines whether or not it is
   * a null point
   * @private
   */
  isValid: function () {
    // undefined is allowed
    return this.value !== null && this.value !== Infinity && this.value !== -Infinity;
  },
  /**
   * @private
   */
  setState: function (state) {
    _Point.default.prototype.setState.call(this, state);
    if (this.graphic) {
      this.graphic.attr({
        zIndex: state === 'hover' ? 1 : 0
      });
    }
  }
  /* eslint-enable valid-jsdoc */
};
/**
 * @private
 * @mixin Highcharts.colorMapSeriesMixin
 */
var colorMapSeriesMixin = {
  pointArrayMap: ['value'],
  axisTypes: ['xAxis', 'yAxis', 'colorAxis'],
  trackerGroups: ['group', 'markerGroup', 'dataLabelsGroup'],
  getSymbol: noop,
  parallelArrays: ['x', 'y', 'value'],
  colorKey: 'value',
  pointAttribs: seriesTypes.column.prototype.pointAttribs,
  /* eslint-disable valid-jsdoc */
  /**
   * Get the color attibutes to apply on the graphic
   * @private
   * @function Highcharts.colorMapSeriesMixin.colorAttribs
   * @param {Highcharts.Point} point
   * @return {Highcharts.SVGAttributes}
   */
  colorAttribs: function (point) {
    var ret = {};
    if (defined(point.color)) {
      ret[this.colorProp || 'fill'] = point.color;
    }
    return ret;
  }
};
var _exports = {
  colorMapPointMixin: colorMapPointMixin,
  colorMapSeriesMixin: colorMapSeriesMixin
};
var _default = exports.default = _exports;
module.exports = exports.default;