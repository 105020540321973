"use es6";

/* *
 *
 *  (c) 2010-2021 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SeriesRegistry = _interopRequireDefault(require("../../Core/Series/SeriesRegistry"));
;
var __extends = void 0 && (void 0).__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var Point = _SeriesRegistry.default.series.prototype.pointClass;
/* *
 *
 *  Class
 *
 * */
var GaugePoint = /** @class */function (_super) {
  __extends(GaugePoint, _super);
  function GaugePoint() {
    /* *
     *
     *  Properties
     *
     * */
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.options = void 0;
    _this.series = void 0;
    _this.shapeArgs = void 0;
    return _this;
    /* eslint-enable valid-jsdoc */
  }
  /* *
   *
   *  Functions
   *
   * */
  /* eslint-disable valid-jsdoc */
  /**
   * Don't do any hover colors or anything
   * @private
   */
  GaugePoint.prototype.setState = function (state) {
    this.state = state;
  };
  return GaugePoint;
}(Point);
/* *
 *
 *  Default export
 *
 * */
var _default = exports.default = GaugePoint;
module.exports = exports.default;