"use strict";
"use es6";

/* *
 *
 *  (c) 2010-2021 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ColumnSeries = _interopRequireDefault(require("../Column/ColumnSeries"));
var _Point = _interopRequireDefault(require("../../Core/Series/Point"));
var _Utilities = _interopRequireDefault(require("../../Core/Utilities"));
var __extends = void 0 && (void 0).__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var isNumber = _Utilities.default.isNumber;
/* *
 *
 * Class
 *
 * */
var WaterfallPoint = /** @class */function (_super) {
  __extends(WaterfallPoint, _super);
  function WaterfallPoint() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.options = void 0;
    _this.series = void 0;
    return _this;
  }
  /* *
   *
   * Functions
   *
   * */
  WaterfallPoint.prototype.getClassName = function () {
    var className = _Point.default.prototype.getClassName.call(this);
    if (this.isSum) {
      className += ' highcharts-sum';
    } else if (this.isIntermediateSum) {
      className += ' highcharts-intermediate-sum';
    }
    return className;
  };
  // Pass the null test in ColumnSeries.translate.
  WaterfallPoint.prototype.isValid = function () {
    return isNumber(this.y) || this.isSum || Boolean(this.isIntermediateSum);
  };
  return WaterfallPoint;
}(_ColumnSeries.default.prototype.pointClass);
/* *
 *
 * Export
 *
 * */
var _default = exports.default = WaterfallPoint;
module.exports = exports.default;