"use es6";

/* *
 *
 *  (c) 2009-2021 Øystein Moseng
 *
 *  Class that can keep track of events added, and clean them up on destroy.
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Globals = _interopRequireDefault(require("../../Core/Globals"));
var _Utilities = _interopRequireDefault(require("../../Core/Utilities"));
;
var addEvent = _Utilities.default.addEvent,
  extend = _Utilities.default.extend;
/* eslint-disable no-invalid-this, valid-jsdoc */
/**
 * @private
 * @class
 */
var EventProvider = function EventProvider() {
  this.eventRemovers = [];
};
extend(EventProvider.prototype, {
  /**
   * Add an event to an element and keep track of it for later removal.
   * Same args as Highcharts.addEvent.
   * @private
   * @return {Function}
   */
  addEvent: function () {
    var remover = addEvent.apply(_Globals.default, arguments);
    this.eventRemovers.push(remover);
    return remover;
  },
  /**
   * Remove all added events.
   * @private
   * @return {void}
   */
  removeAddedEvents: function () {
    this.eventRemovers.forEach(function (remover) {
      remover();
    });
    this.eventRemovers = [];
  }
});
var _default = exports.default = EventProvider;
module.exports = exports.default;