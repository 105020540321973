"use es6";

/* *
 *
 *  (c) 2009-2021 Øystein Moseng
 *
 *  Class that can keep track of elements added to DOM and clean them up on
 *  destroy.
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Globals = _interopRequireDefault(require("../../Core/Globals"));
var _HTMLUtilities = _interopRequireDefault(require("./HTMLUtilities"));
var _Utilities = _interopRequireDefault(require("../../Core/Utilities"));
;
var doc = _Globals.default.doc;
var removeElement = _HTMLUtilities.default.removeElement;
var extend = _Utilities.default.extend;
/* eslint-disable no-invalid-this, valid-jsdoc */
/**
 * @private
 * @class
 */
var DOMElementProvider = function DOMElementProvider() {
  this.elements = [];
};
extend(DOMElementProvider.prototype, {
  /**
   * Create an element and keep track of it for later removal.
   * Same args as document.createElement
   * @private
   */
  createElement: function () {
    var el = doc.createElement.apply(doc, arguments);
    this.elements.push(el);
    return el;
  },
  /**
   * Destroy all created elements, removing them from the DOM.
   * @private
   */
  destroyCreatedElements: function () {
    this.elements.forEach(function (element) {
      removeElement(element);
    });
    this.elements = [];
  }
});
var _default = exports.default = DOMElementProvider;
module.exports = exports.default;