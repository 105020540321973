"use es6";

/* *
 *
 *  (c) 2009-2021 Øystein Moseng
 *
 *  Accessibility module for Highcharts
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _ChartUtilities = _interopRequireDefault(require("./Utils/ChartUtilities"));
var _Globals = _interopRequireDefault(require("../Core/Globals"));
var _KeyboardNavigationHandler = _interopRequireDefault(require("./KeyboardNavigationHandler"));
var _Options = _interopRequireDefault(require("../Core/Options"));
var _Point = _interopRequireDefault(require("../Core/Series/Point"));
var _Series = _interopRequireDefault(require("../Core/Series/Series"));
var _Utilities = _interopRequireDefault(require("../Core/Utilities"));
var _AccessibilityComponent = _interopRequireDefault(require("./AccessibilityComponent"));
var _KeyboardNavigation = _interopRequireDefault(require("./KeyboardNavigation"));
var _LegendComponent = _interopRequireDefault(require("./Components/LegendComponent"));
var _MenuComponent = _interopRequireDefault(require("./Components/MenuComponent"));
var _SeriesComponent = _interopRequireDefault(require("./Components/SeriesComponent/SeriesComponent"));
var _ZoomComponent = _interopRequireDefault(require("./Components/ZoomComponent"));
var _RangeSelectorComponent = _interopRequireDefault(require("./Components/RangeSelectorComponent"));
var _InfoRegionsComponent = _interopRequireDefault(require("./Components/InfoRegionsComponent"));
var _ContainerComponent = _interopRequireDefault(require("./Components/ContainerComponent"));
var _HighContrastMode = _interopRequireDefault(require("./HighContrastMode"));
var _HighContrastTheme = _interopRequireDefault(require("./HighContrastTheme"));
var _Options2 = _interopRequireDefault(require("./Options/Options"));
var _LangOptions = _interopRequireDefault(require("./Options/LangOptions"));
var _DeprecatedOptions = _interopRequireDefault(require("./Options/DeprecatedOptions"));
var _HTMLUtilities = _interopRequireDefault(require("./Utils/HTMLUtilities"));
require("./A11yI18n");
require("./FocusBorder");
;
var doc = _Globals.default.doc;
var defaultOptions = _Options.default.defaultOptions;
var addEvent = _Utilities.default.addEvent,
  extend = _Utilities.default.extend,
  fireEvent = _Utilities.default.fireEvent,
  merge = _Utilities.default.merge;
// Add default options
merge(true, defaultOptions, _Options2.default, {
  accessibility: {
    highContrastTheme: _HighContrastTheme.default
  },
  lang: _LangOptions.default
});
// Expose functionality on Highcharts namespace
_Globals.default.A11yChartUtilities = _ChartUtilities.default;
_Globals.default.A11yHTMLUtilities = _HTMLUtilities.default;
_Globals.default.KeyboardNavigationHandler = _KeyboardNavigationHandler.default;
_Globals.default.AccessibilityComponent = _AccessibilityComponent.default;
/* eslint-disable no-invalid-this, valid-jsdoc */
/**
 * The Accessibility class
 *
 * @private
 * @requires module:modules/accessibility
 *
 * @class
 * @name Highcharts.Accessibility
 *
 * @param {Highcharts.Chart} chart
 *        Chart object
 */
function Accessibility(chart) {
  this.init(chart);
}
Accessibility.prototype = {
  /**
   * Initialize the accessibility class
   * @private
   * @param {Highcharts.Chart} chart
   *        Chart object
   */
  init: function (chart) {
    this.chart = chart;
    // Abort on old browsers
    if (!doc.addEventListener || !chart.renderer.isSVG) {
      chart.renderTo.setAttribute('aria-hidden', true);
      return;
    }
    // Copy over any deprecated options that are used. We could do this on
    // every update, but it is probably not needed.
    (0, _DeprecatedOptions.default)(chart);
    this.initComponents();
    this.keyboardNavigation = new _KeyboardNavigation.default(chart, this.components);
    this.update();
  },
  /**
   * @private
   */
  initComponents: function () {
    var chart = this.chart,
      a11yOptions = chart.options.accessibility;
    this.components = {
      container: new _ContainerComponent.default(),
      infoRegions: new _InfoRegionsComponent.default(),
      legend: new _LegendComponent.default(),
      chartMenu: new _MenuComponent.default(),
      rangeSelector: new _RangeSelectorComponent.default(),
      series: new _SeriesComponent.default(),
      zoom: new _ZoomComponent.default()
    };
    if (a11yOptions.customComponents) {
      extend(this.components, a11yOptions.customComponents);
    }
    var components = this.components;
    this.getComponentOrder().forEach(function (componentName) {
      components[componentName].initBase(chart);
      components[componentName].init();
    });
  },
  /**
   * Get order to update components in.
   * @private
   */
  getComponentOrder: function () {
    if (!this.components) {
      return []; // For zombie accessibility object on old browsers
    }
    if (!this.components.series) {
      return Object.keys(this.components);
    }
    var componentsExceptSeries = Object.keys(this.components).filter(function (c) {
      return c !== 'series';
    });
    // Update series first, so that other components can read accessibility
    // info on points.
    return ['series'].concat(componentsExceptSeries);
  },
  /**
   * Update all components.
   */
  update: function () {
    var components = this.components,
      chart = this.chart,
      a11yOptions = chart.options.accessibility;
    fireEvent(chart, 'beforeA11yUpdate');
    // Update the chart type list as this is used by multiple modules
    chart.types = this.getChartTypes();
    // Update markup
    this.getComponentOrder().forEach(function (componentName) {
      components[componentName].onChartUpdate();
      fireEvent(chart, 'afterA11yComponentUpdate', {
        name: componentName,
        component: components[componentName]
      });
    });
    // Update keyboard navigation
    this.keyboardNavigation.update(a11yOptions.keyboardNavigation.order);
    // Handle high contrast mode
    if (!chart.highContrastModeActive &&
    // Only do this once
    _HighContrastMode.default.isHighContrastModeActive()) {
      _HighContrastMode.default.setHighContrastTheme(chart);
    }
    fireEvent(chart, 'afterA11yUpdate', {
      accessibility: this
    });
  },
  /**
   * Destroy all elements.
   */
  destroy: function () {
    var chart = this.chart || {};
    // Destroy components
    var components = this.components;
    Object.keys(components).forEach(function (componentName) {
      components[componentName].destroy();
      components[componentName].destroyBase();
    });
    // Kill keyboard nav
    if (this.keyboardNavigation) {
      this.keyboardNavigation.destroy();
    }
    // Hide container from screen readers if it exists
    if (chart.renderTo) {
      chart.renderTo.setAttribute('aria-hidden', true);
    }
    // Remove focus border if it exists
    if (chart.focusElement) {
      chart.focusElement.removeFocusBorder();
    }
  },
  /**
   * Return a list of the types of series we have in the chart.
   * @private
   */
  getChartTypes: function () {
    var types = {};
    this.chart.series.forEach(function (series) {
      types[series.type] = 1;
    });
    return Object.keys(types);
  }
};
/**
 * @private
 */
_Globals.default.Chart.prototype.updateA11yEnabled = function () {
  var a11y = this.accessibility,
    accessibilityOptions = this.options.accessibility;
  if (accessibilityOptions && accessibilityOptions.enabled) {
    if (a11y) {
      a11y.update();
    } else {
      this.accessibility = a11y = new Accessibility(this);
    }
  } else if (a11y) {
    // Destroy if after update we have a11y and it is disabled
    if (a11y.destroy) {
      a11y.destroy();
    }
    delete this.accessibility;
  } else {
    // Just hide container
    this.renderTo.setAttribute('aria-hidden', true);
  }
};
// Handle updates to the module and send render updates to components
addEvent(_Globals.default.Chart, 'render', function (e) {
  // Update/destroy
  if (this.a11yDirty && this.renderTo) {
    delete this.a11yDirty;
    this.updateA11yEnabled();
  }
  var a11y = this.accessibility;
  if (a11y) {
    a11y.getComponentOrder().forEach(function (componentName) {
      a11y.components[componentName].onChartRender();
    });
  }
});
// Update with chart/series/point updates
addEvent(_Globals.default.Chart, 'update', function (e) {
  // Merge new options
  var newOptions = e.options.accessibility;
  if (newOptions) {
    // Handle custom component updating specifically
    if (newOptions.customComponents) {
      this.options.accessibility.customComponents = newOptions.customComponents;
      delete newOptions.customComponents;
    }
    merge(true, this.options.accessibility, newOptions);
    // Recreate from scratch
    if (this.accessibility && this.accessibility.destroy) {
      this.accessibility.destroy();
      delete this.accessibility;
    }
  }
  // Mark dirty for update
  this.a11yDirty = true;
});
// Mark dirty for update
addEvent(_Point.default, 'update', function () {
  if (this.series.chart.accessibility) {
    this.series.chart.a11yDirty = true;
  }
});
['addSeries', 'init'].forEach(function (event) {
  addEvent(_Globals.default.Chart, event, function () {
    this.a11yDirty = true;
  });
});
['update', 'updatedData', 'remove'].forEach(function (event) {
  addEvent(_Series.default, event, function () {
    if (this.chart.accessibility) {
      this.chart.a11yDirty = true;
    }
  });
});
// Direct updates (events happen after render)
['afterDrilldown', 'drillupall'].forEach(function (event) {
  addEvent(_Globals.default.Chart, event, function () {
    if (this.accessibility) {
      this.accessibility.update();
    }
  });
});
// Destroy with chart
addEvent(_Globals.default.Chart, 'destroy', function () {
  if (this.accessibility) {
    this.accessibility.destroy();
  }
});