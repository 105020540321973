"use es6";

/* *
 *
 *  (c) 2009-2021 Øystein Moseng
 *
 *  Accessibility component for series and points.
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Globals = _interopRequireDefault(require("../../../Core/Globals"));
var _Utilities = _interopRequireDefault(require("../../../Core/Utilities"));
var _AccessibilityComponent = _interopRequireDefault(require("../../AccessibilityComponent"));
var _SeriesKeyboardNavigation = _interopRequireDefault(require("./SeriesKeyboardNavigation"));
var _NewDataAnnouncer = _interopRequireDefault(require("./NewDataAnnouncer"));
var _ForcedMarkers = _interopRequireDefault(require("./ForcedMarkers"));
var _ChartUtilities = _interopRequireDefault(require("../../Utils/ChartUtilities"));
var _SeriesDescriber = _interopRequireDefault(require("./SeriesDescriber"));
var _Tooltip = _interopRequireDefault(require("../../../Core/Tooltip"));
;
var extend = _Utilities.default.extend;
var hideSeriesFromAT = _ChartUtilities.default.hideSeriesFromAT;
var describeSeries = _SeriesDescriber.default.describeSeries;
// Expose functionality to users
_Globals.default.SeriesAccessibilityDescriber = _SeriesDescriber.default;
// Handle forcing markers
(0, _ForcedMarkers.default)();
/* eslint-disable no-invalid-this, valid-jsdoc */
/**
 * The SeriesComponent class
 *
 * @private
 * @class
 * @name Highcharts.SeriesComponent
 */
var SeriesComponent = function SeriesComponent() {};
SeriesComponent.prototype = new _AccessibilityComponent.default();
extend(SeriesComponent.prototype, /** @lends Highcharts.SeriesComponent */{
  /**
   * Init the component.
   */
  init: function () {
    this.newDataAnnouncer = new _NewDataAnnouncer.default(this.chart);
    this.newDataAnnouncer.init();
    this.keyboardNavigation = new _SeriesKeyboardNavigation.default(this.chart, this.keyCodes);
    this.keyboardNavigation.init();
    this.hideTooltipFromATWhenShown();
    this.hideSeriesLabelsFromATWhenShown();
  },
  /**
   * @private
   */
  hideTooltipFromATWhenShown: function () {
    var component = this;
    this.addEvent(_Tooltip.default, 'refresh', function () {
      if (this.chart === component.chart && this.label && this.label.element) {
        this.label.element.setAttribute('aria-hidden', true);
      }
    });
  },
  /**
   * @private
   */
  hideSeriesLabelsFromATWhenShown: function () {
    this.addEvent(this.chart, 'afterDrawSeriesLabels', function () {
      this.series.forEach(function (series) {
        if (series.labelBySeries) {
          series.labelBySeries.attr('aria-hidden', true);
        }
      });
    });
  },
  /**
   * Called on chart render. It is necessary to do this for render in case
   * markers change on zoom/pixel density.
   */
  onChartRender: function () {
    var chart = this.chart;
    chart.series.forEach(function (series) {
      var shouldDescribeSeries = (series.options.accessibility && series.options.accessibility.enabled) !== false && series.visible;
      if (shouldDescribeSeries) {
        describeSeries(series);
      } else {
        hideSeriesFromAT(series);
      }
    });
  },
  /**
   * Get keyboard navigation handler for this component.
   * @return {Highcharts.KeyboardNavigationHandler}
   */
  getKeyboardNavigation: function () {
    return this.keyboardNavigation.getKeyboardNavigationHandler();
  },
  /**
   * Remove traces
   */
  destroy: function () {
    this.newDataAnnouncer.destroy();
    this.keyboardNavigation.destroy();
  }
});
var _default = exports.default = SeriesComponent;
module.exports = exports.default;