"use strict";
"use es6";

/* *
 *
 *  (c) 2009-2021 Øystein Moseng
 *
 *  Accessibility component for chart container.
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AccessibilityComponent = _interopRequireDefault(require("../AccessibilityComponent"));
var _ChartUtilities = _interopRequireDefault(require("../Utils/ChartUtilities"));
var _Globals = _interopRequireDefault(require("../../Core/Globals"));
var _HTMLUtilities = _interopRequireDefault(require("../Utils/HTMLUtilities"));
var _Utilities = _interopRequireDefault(require("../../Core/Utilities"));
var unhideChartElementFromAT = _ChartUtilities.default.unhideChartElementFromAT,
  getChartTitle = _ChartUtilities.default.getChartTitle;
var doc = _Globals.default.doc;
var stripHTMLTags = _HTMLUtilities.default.stripHTMLTagsFromString;
var extend = _Utilities.default.extend;
/* eslint-disable valid-jsdoc */
/**
 * The ContainerComponent class
 *
 * @private
 * @class
 * @name Highcharts.ContainerComponent
 */
var ContainerComponent = function ContainerComponent() {};
ContainerComponent.prototype = new _AccessibilityComponent.default();
extend(ContainerComponent.prototype, /** @lends Highcharts.ContainerComponent */{
  /**
   * Called on first render/updates to the chart, including options changes.
   */
  onChartUpdate: function () {
    this.handleSVGTitleElement();
    this.setSVGContainerLabel();
    this.setGraphicContainerAttrs();
    this.setRenderToAttrs();
    this.makeCreditsAccessible();
  },
  /**
   * @private
   */
  handleSVGTitleElement: function () {
    var chart = this.chart,
      titleId = 'highcharts-title-' + chart.index,
      titleContents = stripHTMLTags(chart.langFormat('accessibility.svgContainerTitle', {
        chartTitle: getChartTitle(chart)
      }));
    if (titleContents.length) {
      var titleElement = this.svgTitleElement = this.svgTitleElement || doc.createElementNS('http://www.w3.org/2000/svg', 'title');
      titleElement.textContent = titleContents;
      titleElement.id = titleId;
      chart.renderTo.insertBefore(titleElement, chart.renderTo.firstChild);
    }
  },
  /**
   * @private
   */
  setSVGContainerLabel: function () {
    var chart = this.chart,
      svgContainerLabel = chart.langFormat('accessibility.svgContainerLabel', {
        chartTitle: getChartTitle(chart)
      });
    if (chart.renderer.box && svgContainerLabel.length) {
      chart.renderer.box.setAttribute('aria-label', svgContainerLabel);
    }
  },
  /**
   * @private
   */
  setGraphicContainerAttrs: function () {
    var chart = this.chart,
      label = chart.langFormat('accessibility.graphicContainerLabel', {
        chartTitle: getChartTitle(chart)
      });
    if (label.length) {
      chart.container.setAttribute('aria-label', label);
    }
  },
  /**
   * @private
   */
  setRenderToAttrs: function () {
    var chart = this.chart;
    if (chart.options.accessibility.landmarkVerbosity !== 'disabled') {
      chart.renderTo.setAttribute('role', 'region');
    } else {
      chart.renderTo.removeAttribute('role');
    }
    chart.renderTo.setAttribute('aria-label', chart.langFormat('accessibility.chartContainerLabel', {
      title: getChartTitle(chart),
      chart: chart
    }));
  },
  /**
   * @private
   */
  makeCreditsAccessible: function () {
    var chart = this.chart,
      credits = chart.credits;
    if (credits) {
      if (credits.textStr) {
        credits.element.setAttribute('aria-label', chart.langFormat('accessibility.credits', {
          creditsStr: stripHTMLTags(credits.textStr)
        }));
      }
      unhideChartElementFromAT(chart, credits.element);
    }
  },
  /**
   * Accessibility disabled/chart destroyed.
   */
  destroy: function () {
    this.chart.renderTo.setAttribute('aria-hidden', true);
  }
});
var _default = exports.default = ContainerComponent;
module.exports = exports.default;