"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var palette = {
  /**
   * Colors for data series and points.
   */
  colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
  /**
   * Chart background, point stroke for markers and columns etc
   */
  backgroundColor: '#ffffff',
  /**
   * Strong text.
   */
  neutralColor100: '#000000',
  /**
   * Main text and some strokes.
   */
  neutralColor80: '#333333',
  /**
   * Axis labels, axis title, connector fallback.
   */
  neutralColor60: '#666666',
  /**
   * Credits text, export menu stroke.
   */
  neutralColor40: '#999999',
  /**
   * Disabled texts, button strokes, crosshair etc.
   */
  neutralColor20: '#cccccc',
  /**
   * Grid lines etc.
   */
  neutralColor10: '#e6e6e6',
  /**
   * Minor grid lines etc.
   */
  neutralColor5: '#f2f2f2',
  /**
   * Tooltip backgroud, button fills, map null points.
   */
  neutralColor3: '#f7f7f7',
  /**
   * Drilldown clickable labels, color axis max color.
   */
  highlightColor100: '#003399',
  /**
   * Selection marker, menu hover, button hover, chart border, navigator series.
   */
  highlightColor80: '#335cad',
  /**
   * Navigator mask fill.
   */
  highlightColor60: '#6685c2',
  /**
   * Ticks and axis line.
   */
  highlightColor20: '#ccd6eb',
  /**
   * Pressed button, color axis min color.
   */
  highlightColor10: '#e6ebf5',
  /**
   * Positive indicator color
   */
  indicatorPositiveLine: '#06b535',
  /**
   * Negative indicator color
   */
  indicatorNegativeLine: '#f21313'
};
var _default = exports.default = palette;
module.exports = exports.default;