"use es6";

/* *
 *
 *  (c) 2009-2021 Øystein Moseng
 *
 *  Create announcer to speak messages to screen readers and other AT.
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Globals = _interopRequireDefault(require("../../Core/Globals"));
var _AST = _interopRequireDefault(require("../../Core/Renderer/HTML/AST"));
var _DOMElementProvider = _interopRequireDefault(require("./DOMElementProvider"));
var _HTMLUtilities = _interopRequireDefault(require("./HTMLUtilities"));
;
var doc = _Globals.default.doc;
var setElAttrs = _HTMLUtilities.default.setElAttrs,
  visuallyHideElement = _HTMLUtilities.default.visuallyHideElement;
var Announcer = /** @class */function () {
  function Announcer(chart, type) {
    this.chart = chart;
    this.domElementProvider = new _DOMElementProvider.default();
    this.announceRegion = this.addAnnounceRegion(type);
  }
  Announcer.prototype.destroy = function () {
    this.domElementProvider.destroyCreatedElements();
  };
  Announcer.prototype.announce = function (message) {
    var _this = this;
    _AST.default.setElementHTML(this.announceRegion, message);
    // Delete contents after a little while to avoid user finding the live
    // region in the DOM.
    if (this.clearAnnouncementRegionTimer) {
      clearTimeout(this.clearAnnouncementRegionTimer);
    }
    this.clearAnnouncementRegionTimer = setTimeout(function () {
      _this.announceRegion.innerHTML = '';
      delete _this.clearAnnouncementRegionTimer;
    }, 1000);
  };
  Announcer.prototype.addAnnounceRegion = function (type) {
    var chartContainer = this.chart.announcerContainer || this.createAnnouncerContainer();
    var div = this.domElementProvider.createElement('div');
    setElAttrs(div, {
      'aria-hidden': false,
      'aria-live': type
    });
    visuallyHideElement(div);
    chartContainer.appendChild(div);
    return div;
  };
  Announcer.prototype.createAnnouncerContainer = function () {
    var chart = this.chart;
    var container = doc.createElement('div');
    setElAttrs(container, {
      'aria-hidden': false,
      style: 'position:relative',
      'class': 'highcharts-announcer-container'
    });
    chart.renderTo.insertBefore(container, chart.renderTo.firstChild);
    chart.announcerContainer = container;
    return container;
  };
  return Announcer;
}();
_Globals.default.Announcer = Announcer;
var _default = exports.default = Announcer;
module.exports = exports.default;